import React, { useRef, useEffect, useCallback } from 'react';
import { Socket } from 'socket.io-client';

type PhoneHeadsetTakeSnapshotProps = {
  socket: Socket | null;
  stream: MediaStream | null;
};
export const PhoneHeadsetTakeSnapshot = ({ socket, stream }: PhoneHeadsetTakeSnapshotProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const { current: video } = videoRef;

    if (video) {
      video.srcObject = stream;
    }
  }, [stream]);

  const handleTakeScreenshot = useCallback(() => {
    if (!stream || !socket) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (!canvas || !video) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    const { width: streamWidth, height: streamHeight } = stream.getVideoTracks()[0].getSettings();

    if (streamWidth && streamHeight) {
      canvas.width = streamWidth;
      canvas.height = streamHeight;
      context.drawImage(video, 0, 0, streamWidth, streamHeight);
      const base64 = canvas.toDataURL();
      const cleanBase64 = base64.replace(/^data:image\/(png|jpg);base64,/, '');
      socket.emit('send_snapshot', cleanBase64);
    }
  }, [socket, stream]);

  useEffect(() => {
    if (!socket) return;
    socket.on('snapshot', handleTakeScreenshot);

    return () => {
      socket.off('snapshot', handleTakeScreenshot);
    };
  }, [socket, handleTakeScreenshot]);

  return (
    <>
      <video ref={videoRef} style={{ display: 'none' }} autoPlay playsInline />
      <canvas
        ref={canvasRef}
        style={{
          display: 'none',
        }}
      ></canvas>
    </>
  );
};
